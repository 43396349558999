import React, { useState } from 'react';

const GSTPage = () => {
    const [amount, setAmount] = useState('');
    const [rate, setRate] = useState('');
    const [gst, setGst] = useState(null);

    const calculateGST = () => {
        const gstAmount = (amount * rate) / 100;
        setGst(gstAmount);
    };

    return (
        <div className="container mx-auto p-4 py-12 mt-8 w-full lg:py-24">
            <h1 className="text-4xl font-bold text-center mb-8">Goods and Services Tax (GST)</h1>

            {/* Introduction Section */}
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Introduction to GST</h2>
                <p className="text-lg">
                    Goods and Services Tax (GST) is an indirect tax used in India on the supply of goods and services.
                    It is a comprehensive, multistage, destination-based tax: comprehensive because it has subsumed almost all the indirect taxes except a few state taxes.
                </p>
            </section>

            {/* Benefits Section */}
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Benefits of GST</h2>
                <ul className="list-disc list-inside text-lg">
                    <li>Elimination of the cascading effect of taxes.</li>
                    <li>Higher threshold for registration.</li>
                    <li>Composition scheme for small businesses.</li>
                    <li>Simple and easy online procedure.</li>
                    <li>Defined treatment for e-commerce.</li>
                </ul>
            </section>

            {/* Rates Section */}
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">GST Rates</h2>
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-2">Category</th>
                            <th className="py-2">GST Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border px-4 py-2">Essential Items</td>
                            <td className="border px-4 py-2">5%</td>
                        </tr>
                        <tr className="bg-gray-100">
                            <td className="border px-4 py-2">Standard Items</td>
                            <td className="border px-4 py-2">12%</td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2">Luxury Items</td>
                            <td className="border px-4 py-2">18%</td>
                        </tr>
                        <tr className="bg-gray-100">
                            <td className="border px-4 py-2">Sin Goods</td>
                            <td className="border px-4 py-2">28%</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            {/* Calculator Section */}
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">GST Calculator</h2>
                <div className="flex flex-col space-y-4">
                    <input
                        type="number"
                        placeholder="Enter amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="p-2 border rounded"
                    />
                    <input
                        type="number"
                        placeholder="Enter GST rate"
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                        className="p-2 border rounded"
                    />
                    <button
                        onClick={calculateGST}
                        className="p-2 bg-blue-500 text-white rounded"
                    >
                        Calculate
                    </button>
                    {gst !== null && (
                        <div className="mt-4">
                            <p className="text-lg">GST Amount: ₹{gst}</p>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
}

export default GSTPage;
