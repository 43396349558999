import React, { useState } from 'react';

const LoanCalculator = () => {
    const [principal, setPrincipal] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [years, setYears] = useState('');
    const [monthlyEMI, setMonthlyEMI] = useState(null);
    const [totalInterest, setTotalInterest] = useState(null);
    const [avgInterest, setAvgInterest] = useState(null);

    const calculateEMI = (principal, rate, years) => {
        const monthlyRate = rate / 100 / 12;
        const numberOfPayments = years * 12;
        const emi = principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
        const totalPayment = emi * numberOfPayments;
        const totalInterestPaid = totalPayment - principal;
        const avgInterestPaid = totalInterestPaid / numberOfPayments;
        return {
            emi: emi.toFixed(2),
            totalInterest: totalInterestPaid.toFixed(2),
            avgInterest: avgInterestPaid.toFixed(2),
        };
    };

    const handleCalculate = () => {
        const { emi, totalInterest, avgInterest } = calculateEMI(principal, interestRate, years);
        setMonthlyEMI(emi);
        setTotalInterest(totalInterest);
        setAvgInterest(avgInterest);
    };

    return (
        <div className="md:flex items-center p-8  ">
            <h2 className="my-2 text-center text-2xl text-blue-900 uppercase font-bold">Loan Calculator</h2>
            <div className=" md:flex ">
                <div className='pl-8'>
                    <label className="block  font-semibold mb-2">Principal Amount (₹)</label>
                    <input
                        type="number"
                        value={principal}
                        onChange={(e) => setPrincipal(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>
                <div className='pl-8'>
                    <label className="block font-semibold mb-2">Annual Interest Rate (%)</label>
                    <input
                        type="number"
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>
                <div className='pl-8'>
                    <label className="block font-semibold mb-2">Loan Term (Years)</label>
                    <input
                        type="number"
                        value={years}
                        onChange={(e) => setYears(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>
                <button
                    onClick={handleCalculate}
                    className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 mt-8 ml-2 text-base shadow-xl rounded-2xl sm:w-auto"
                >
                    Calculate
                </button>
            </div>
            {monthlyEMI !== null && (
                <div className="mt-6 p-6 bg-green-100 text-green-700 rounded-lg ml-4">
                    <p className="text-xl font-semibold">Monthly EMI: ₹{monthlyEMI}</p>
                    <p className="text-xl font-semibold">Total Interest: ₹{totalInterest}</p>
                    <p className="text-xl font-semibold">Average Monthly Interest: ₹{avgInterest}</p>
                </div>
            )}
        </div>
    );
};

export default LoanCalculator;
