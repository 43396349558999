import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PanForm = () => {
  const [name, setName] = useState('');
  const [pan, setPan] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Name:', name);
    console.log('PAN:', pan);
  };

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/mobileVerification');
    console.log('Next button clicked');
  };

  const handleBack = () => {
    navigate('/loanInformation');
    console.log('Back button clicked');

  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-96">
        <h2 className="text-2xl text-blue-900 font-bold mb-4">Validate your PAN</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Name as on PAN card :
          </label>
          <input
            type="text"
            placeholder="Enter exact name as on PAN"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            PAN number :
          </label>
          <input
            type="text"
            placeholder="Eg: ASDFG4567H"
            value={pan}
            onChange={(e) => setPan(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleBack}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Back
          </button>
          {/* <button
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button> */}
          <button
            type="button"
            onClick={handleNext}
            className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default PanForm;
