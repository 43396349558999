import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UploadDocuments = () => {
    const navigate = useNavigate();

    const [documents, setDocuments] = useState({
        panCard: null,
        aadhaarCard: null,
        passportPhoto: null,
        itReturn: null,
        bankStatement: null,
        salarySlips: null,
    });

    const handleFileChange = (e) => {
        setDocuments({
            ...documents,
            [e.target.name]: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        navigate('/nextPage'); // Replace '/nextPage' with the actual path
    };



    return (
        <div className="md:mt-28  flex justify-center items-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h1 className="text-2xl font-bold mb-6">Upload Documents</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">PAN Card</label>
                        <input
                            type="file"
                            name="panCard"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="PAN Card"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Aadhaar Card</label>
                        <input
                            type="file"
                            name="aadhaarCard"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Aadhaar Card"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Passport-sized Photograph</label>
                        <input
                            type="file"
                            name="passportPhoto"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Passport-sized Photograph"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">IT Return/Form 16</label>
                        <input
                            type="file"
                            name="itReturn"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="IT Return/Form 16"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Bank Statement</label>
                        <input
                            type="file"
                            name="bankStatement"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Bank Statement"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Salary Slips</label>
                        <input
                            type="file"
                            name="salarySlips"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Salary Slips"
                        />
                    </div>

                    <div className="flex justify-between">
                        <button
                            type="button"
                            onClick={() => navigate('/bankDetails')} // Replace '/previousPage' with the actual path
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-900 text-white rounded"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UploadDocuments;
