import React, { useState } from 'react';

const TaxInfoPage = () => {
    const [income, setIncome] = useState('');
    const [deductions, setDeductions] = useState('');
    const [tax, setTax] = useState(null);

    const handleCalculateTax = () => {
        const taxableIncome = income - deductions;
        let estimatedTax = 0;

        // Simple tax calculation (replace with real tax brackets as needed)
        if (taxableIncome > 0) {
            if (taxableIncome <= 9875) {
                estimatedTax = taxableIncome * 0.1;
            } else if (taxableIncome <= 40125) {
                estimatedTax = 987.5 + (taxableIncome - 9875) * 0.12;
            } else if (taxableIncome <= 85525) {
                estimatedTax = 4617.5 + (taxableIncome - 40125) * 0.22;
            } else if (taxableIncome <= 163300) {
                estimatedTax = 14605.5 + (taxableIncome - 85525) * 0.24;
            } else {
                estimatedTax = 33271.5 + (taxableIncome - 163300) * 0.32;
            }
        }

        setTax(estimatedTax);
    };

    return (
        <div className="container mx-auto p-4 py-12 mt-8 w-full lg:py-24">



            <div className="min-h-screen bg-gray-50 py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-extrabold text-center text-gray-900 mb-8">Tax Information</h1>

                    <div className="bg-white shadow sm:rounded-lg mb-8 p-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">What is Income Tax?</h2>
                        <p className="text-gray-700 leading-relaxed">
                            Income tax is a tax that governments impose on income generated by businesses and individuals within their jurisdiction. By law, taxpayers must file an income tax return annually to determine their tax obligations.
                        </p>
                    </div>

                    <div className="bg-white shadow sm:rounded-lg mb-8 p-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">How is Income Tax Calculated?</h2>
                        <p className="text-gray-700 leading-relaxed">
                            Income tax is calculated based on the taxable income of an individual or a corporation. Taxable income is the portion of your income that is subject to taxation after deductions and exemptions.
                        </p>
                    </div>

                    <div className="bg-white shadow sm:rounded-lg mb-8 p-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Types of Income Tax</h2>
                        <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                            <li>Federal Income Tax</li>
                            <li>State Income Tax</li>
                            <li>Local Income Tax</li>
                        </ul>
                    </div>

                    <div className="bg-white shadow sm:rounded-lg mb-8 p-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Tax Deductions and Credits</h2>
                        <p className="text-gray-700 leading-relaxed">
                            Tax deductions reduce your taxable income, while tax credits reduce your tax liability. Common deductions include mortgage interest and charitable donations. Credits include the earned income credit and child tax credit.
                        </p>
                    </div>

                    <div className="bg-white shadow sm:rounded-lg mb-8 p-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Filing Your Taxes</h2>
                        <p className="text-gray-700 leading-relaxed">
                            Taxpayers must file an annual tax return to report their income, expenses, and other tax-related information. There are various methods to file your taxes including electronic filing, paper filing, and using a tax professional.
                        </p>
                    </div>

                    <div className="bg-white shadow sm:rounded-lg mb-8 p-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Tax Deadlines</h2>
                        <p className="text-gray-700 leading-relaxed">
                            The deadline to file your federal income tax return is typically April 15th each year. If you need more time, you can request an extension.
                        </p>
                    </div>


                </div>
            </div>


            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">


                {/* Existing content */}

                <div className="bg-white shadow sm:rounded-lg mb-8 p-6">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Tax Calculator</h2>
                    <div className="mb-4">
                        <label className="block text-gray-700">Annual Income</label>
                        <input
                            type="number"
                            value={income}
                            onChange={(e) => setIncome(e.target.value)}
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Deductions</label>
                        <input
                            type="number"
                            value={deductions}
                            onChange={(e) => setDeductions(e.target.value)}
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    <button
                        onClick={handleCalculateTax}
                        className="bg-blue-500 text-white p-2 rounded"
                    >
                        Calculate Tax
                    </button>
                    {tax !== null && (
                        <div className="mt-4">
                            <h3 className="text-xl font-semibold text-gray-800">Estimated Tax:</h3>
                            <p className="text-gray-700">${tax.toFixed(2)}</p>
                        </div>
                    )}
                </div>

                {/* Additional existing content */}
            </div>
        </div>
    );
};

export default TaxInfoPage;
