import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BorrowedBeforeForm = () => {
  const [borrowedBefore, setBorrowedBefore] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Have you borrowed before?', borrowedBefore);
    // Here you can handle the submission logic as per your application's requirements
  };

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/proofOfResidence');
    console.log('Next button clicked');
  };

  const handleBack = () => {
    navigate('/aboutWork');
    console.log('Back button clicked');
  };


  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full sm:w-96">
        <h2 className="text-2xl font-bold mb-4">Have You Borrowed Before?</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Select</label>
          <div className="flex items-center">
            <input
              type="radio"
              id="yes"
              name="borrowedBefore"
              value="yes"
              checked={borrowedBefore === 'yes'}
              onChange={() => setBorrowedBefore('yes')}
              className="mr-2 leading-tight"
            />
            <label htmlFor="yes" className="text-sm">
              Yes
            </label>
          </div>
          <div className="flex items-center mt-2">
            <input
              type="radio"
              id="no"
              name="borrowedBefore"
              value="no"
              checked={borrowedBefore === 'no'}
              onChange={() => setBorrowedBefore('no')}
              className="mr-2 leading-tight"
            />
            <label htmlFor="no" className="text-sm">
              No
            </label>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleBack}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Back
          </button>
          {/* <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button> */}
          <button
            type="button"
            onClick={handleNext}
            className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default BorrowedBeforeForm;
