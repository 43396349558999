import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const EmailVerificationForm = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationInProgress, setVerificationInProgress] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (verificationInProgress) {
      console.log('Verifying OTP:', otp);
      // Here you would typically send OTP for verification
      // Simulating verification success for demonstration
      alert('Email verification successful!');
    } else {
      console.log('Sending OTP to:', email);
      // Here you would typically send OTP to the provided email address
      // and switch to OTP verification mode
      setVerificationInProgress(true);
    }
  };

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/personalInformation');
    console.log('Next button clicked');
  };

  const handleBack = () => {
    navigate('/mobileVerification');
    console.log('Back button clicked');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Verify your Email Address</h2>
        {verificationInProgress ? (
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Enter OTP</label>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        ) : (
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email Address
            </label>
            <input
              type="email"
              placeholder="Enter Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        )}
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleBack}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Back
          </button>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {verificationInProgress ? 'Verify OTP' : 'Send OTP'}
          </button>
          <button
            type="button"
            onClick={handleNext}
            className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailVerificationForm;
