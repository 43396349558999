import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WorkInformationForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [designation, setDesignation] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [monthlySalary, setMonthlySalary] = useState('');
  const [employmentType, setEmploymentType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Company Name:', companyName);
    console.log('Designation:', designation);
    console.log('Years of Experience:', yearsOfExperience);
    console.log('Monthly Salary:', monthlySalary);
    console.log('Employment Type:', employmentType);
    // Here you would typically submit this information to a backend server
    alert('Work information submitted successfully!');
  };

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/borrowedBefore');
    console.log('Next button clicked');
  };

  const handleBack = () => {
    navigate('/personalInformation');
    console.log('Back button clicked');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full sm:w-96">
        <h2 className="text-2xl font-bold mb-4">About Your Work</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Company Name</label>
          <input
            type="text"
            placeholder="Enter Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Designation</label>
          <input
            type="text"
            placeholder="Enter Designation"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Years of Experience</label>
          <input
            type="text"
            placeholder="Enter Years of Experience"
            value={yearsOfExperience}
            onChange={(e) => setYearsOfExperience(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Monthly Salary</label>
          <input
            type="text"
            placeholder="Enter Monthly Salary"
            value={monthlySalary}
            onChange={(e) => setMonthlySalary(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">Employment Type</label>
          <select
            value={employmentType}
            onChange={(e) => setEmploymentType(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select Employment Type</option>
            <option value="Full-time">Full-time</option>
            <option value="Part-time">Part-time</option>
            <option value="Contract">Contract</option>
            <option value="Freelance">Freelance</option>
            <option value="Internship">Internship</option>
          </select>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleBack}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Back
          </button>
          {/* <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button> */}
          <button
            type="button"
            onClick={handleNext}
            className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default WorkInformationForm;
