import React from 'react';
import { useNavigate } from 'react-router-dom';

const ApplyNow = () => {


    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/loanInformation');
    };

    return (
        <div className="pt-0 w-screen h-screen py-12 flex justify-center md:mt-40">
            <div className="bg-white flex flex-col md:flex-row">
                <div className="md:w-1/2 p-6 md:p-40 w-full h-full text-center md:text-left">
                    <h1 className="text-3xl font-extrabold mb-6 text-blue-800">
                        Welcome to Yaazh Finance
                    </h1>
                </div>
                <div className="md:w-1/2 p-6 w-full">
                    <p className="mb-4 text-blue-900 font-bold text-2xl text-center">
                        Here are a few things you will need to get started:
                    </p>
                    <ul className="list-disc list-inside mb-8 text-gray-700 space-y-2 py-10">
                        <li className='lg:py-2'>Your phone for the OTP</li>
                        <li className='lg:py-2'>PAN Card</li>
                        <li className='g:py-2'>Recent passport-sized photograph</li>
                        <li className='lg:py-2'>IT Return/Form 16 for last year</li>
                        <li className='lg:py-2'>Bank statements for the last 6 months</li>
                        <li className='lg:py-2'>Salary slips for the last 3 months</li>
                        <li className='lg:py-2'>
                            Masked e-Aadhaar or any proof of current address
                            <span className="text-blue-900 cursor-pointer"> info_outline </span>
                        </li>
                    </ul>
                    <div className='mt-0'>
                        <button onClick={handleButtonClick} className="w-full bg-gradient-to-r from-blue-900 to-blue-900 text-white py-3 rounded-xl font-semibold duration-300">
                            GET STARTED
                        </button>
                    </div>
                </div>
            </div>
        </div>


    );

}

export default ApplyNow;
