import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const MortageLoan = () => {
  const aeSyllabusUrl = '/AESyllabus.pdf';
  const syllabusUrl = '/syllabus.pdf';
  const previousYearTamilUrl = '/previousquestionstamil.pdf'
  const previousYearGeneralUrl = '/PreviousYearQuestionGeneral.pdf'


  const [loanAmount, setLoanAmount] = useState('');
  const [loanPurpose, setLoanPurpose] = useState('');
  const [tenure, setTenure] = useState('');

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/applynow');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Loan Amount: ${loanAmount}, Loan Purpose: ${loanPurpose}, Tenure: ${tenure} months`);
  };

  return (
    <div className='md:mt-28 '>
      <div id="services" className="bg-gray-100 py-12" >
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Mortage Loan</h2>

            <div className='flex justify-center'>
              <div className='w-24 border-b-4 border-blue-900'></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">A mortgage loan is a type of loan used to purchase real estate, where the property itself serves as collateral.</h2>
          </div>

          <div className="px-12" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">

              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />*/}
                  <h2 className="font-semibold my-4 text-2xl text-center">Mortage Loan</h2>
                  <p>
                  </p>
                  <p>A mortgage loan is a financial arrangement used to purchase real estate, with the property serving as collateral. The borrower makes regular payments over a specified period until the loan is fully repaid.</p>
                  <h2 className="font-semibold my-4 text-2xl "> Eligibility Criteria</h2>
                  <p>Credit Score, Income and Employment, Debt-to-Income Ratio, Down Payment, Property Appraisal, Proof of Assets </p>
                  <p className=' font-semibold text-lg'>For account opening:: 3% to 20% of the property's purchase price</p>

                  <br />
                  {/* <Link to={{
                    pathname: "/signup",
                  }}
                    state={{
                      planCost: "5000",
                      planName: "Communication",
                      planDuration: "2 months",
                    }}
                    exact className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                    Apply Now
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                  </Link> */}
                </div>
              </div>
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="">
                  <div className="m-2 text-justify text-sm">
                    <form onSubmit={handleSubmit} className="">
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Loan Amount:
                        </label>
                        <input
                          type="number"
                          value={loanAmount}
                          onChange={(e) => setLoanAmount(e.target.value)}
                          max="500000"
                          required
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />

                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          LoanPurpose:
                          <select
                            value={loanPurpose}
                            onChange={(e) => setLoanPurpose(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="">Select Purpose</option>
                            <option value="personal">Mortgage Loan</option>
                            {/* <option value="education">Education Loan</option> */}
                          </select>
                        </label>
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Tenure:
                          <select
                            value={tenure}
                            onChange={(e) => setTenure(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="">Select Tenure</option>
                            <option value="6">6 months</option>
                            <option value="12">12 months</option>
                            <option value="24">24 months</option>
                            <option value="36">36 months</option>
                          </select>
                        </label>
                      </div>


                      <Link className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                        <button onClick={handleButtonClick}>Apply Now</button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />

      </div>
    </div>
  );
};

export default MortageLoan;
