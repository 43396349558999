import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BankDetails = () => {
    const navigate = useNavigate();

    const [accountHolderName, setAccountHolderName] = useState('');
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [accountType, setAccountType] = useState('Savings');
    const [bankStatement, setBankStatement] = useState(null);

    const handleFileChange = (e) => setBankStatement(e.target.files[0]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        navigate('/nextPage'); // Replace '/nextPage' with the actual path
    };

    const handleNext = () => {
        navigate('/uploadDocuments');
        console.log('Next button clicked');
    };

    const handleBack = () => {
        navigate('/proofOfResidence');
        console.log('Back button clicked');
    };

    return (
        <div className=" md:mt-28  flex justify-center items-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h1 className="text-2xl font-bold mb-6">Bank Details</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Account Holder's Name</label>
                        <input
                            type="text"
                            value={accountHolderName}
                            onChange={(e) => setAccountHolderName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Account Holder's Name"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Bank Name</label>
                        <input
                            type="text"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Bank Name"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Branch Name</label>
                        <input
                            type="text"
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Branch Name"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Account Number</label>
                        <input
                            type="text"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Account Number"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">IFSC Code</label>
                        <input
                            type="text"
                            value={ifscCode}
                            onChange={(e) => setIfscCode(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="IFSC Code"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Account Type</label>
                        <select
                            value={accountType}
                            onChange={(e) => setAccountType(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            aria-label="Account Type"
                        >
                            <option value="Savings">Savings</option>
                            <option value="Current">Current</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Upload Bank Statement</label>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
                            required
                            aria-label="Upload Bank Statement"
                        />
                    </div>

                    <div className="flex justify-between">

                        <button
                            type="button"
                            onClick={handleBack}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Back
                        </button>
                        {/* <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Submit
                        </button> */}
                        <button
                            type="button"
                            onClick={handleNext}
                            className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BankDetails;
