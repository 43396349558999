import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoanInformation = () => {


  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/applynow');


  };

  const handleButtonClick1 = () => {
    navigate('/panForm');


  };

  const [amount, setAmount] = useState(500000);
  const [purpose, setPurpose] = useState('Education');
  const [tenure, setTenure] = useState(6);

  const handleAmountChange = (e) => setAmount(e.target.value);
  const handlePurposeChange = (e) => setPurpose(e.target.value);
  const handleTenureChange = (e) => setTenure(e.target.value);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4 ">
      <div className="bg-white p-8 md:p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-xl md:text-2xl text-blue-900 font-bold mb-4 md:mb-6">Tell us about your requirement</h1>

        <div className="mb-4">
          <label className="block text-gray-700">Required loan amount : </label>
          <input
            type="number"
            value={amount}
            onChange={handleAmountChange}
            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
            max="500000"
          />
          <small className="text-gray-500">Upto ₹ 5,00,000</small>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Purpose of loan : </label>
          <select
            value={purpose}
            onChange={handlePurposeChange}
            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
          >
            <option value="Education">Education</option>
            <option value="Home">Home</option>
            <option value="Business">Business</option>
            <option value="Personal">Personal</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Loan Tenure : </label>
          <input
            type="number"
            value={tenure}
            onChange={handleTenureChange}
            className="w-full px-3 py-2 border border-gray-300 rounded mt-1"
          />
          <small className="text-gray-500">Select tenure in months :</small>
        </div>

        <div className="mb-4 text-gray-500">
          *Prepay anytime. No prepayment penalties.
        </div>

        <div className="flex justify-between">
          <button onClick={handleButtonClick} className="px-3 py-2 bg-gray-300 text-gray-700 rounded">Back</button>
          <button onClick={handleButtonClick1} className="px-3 py-2 bg-blue-900 text-white rounded">Next</button>
        </div>
      </div>
    </div>

  );
};

export default LoanInformation;
