import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProofOfResidenceForm = () => {
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Address Line 1:', addressLine1);
        console.log('Address Line 2:', addressLine2);
        console.log('City:', city);
        console.log('State:', state);
        console.log('Zip Code:', zipCode);
        // Here you can handle the submission logic as per your application's requirements
        alert('Proof of residence submitted successfully!');
    };

    const navigate = useNavigate();

    const handleNext = () => {
        navigate('/bankDetails');
        console.log('Next button clicked');
    };

    const handleBack = () => {
        navigate('/personalInformation');
        console.log('Back button clicked');
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full sm:w-96">
                <h2 className="text-2xl font-bold mb-4">Proof of Residence</h2>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Address Line 1</label>
                    <input
                        type="text"
                        placeholder="Enter Address Line 1"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Address Line 2</label>
                    <input
                        type="text"
                        placeholder="Enter Address Line 2"
                        value={addressLine2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">City</label>
                    <input
                        type="text"
                        placeholder="Enter City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">State</label>
                    <input
                        type="text"
                        placeholder="Enter State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Zip Code</label>
                    <input
                        type="text"
                        placeholder="Enter Zip Code"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="button"
                        onClick={handleBack}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Back
                    </button>
                    {/* <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button> */}
                    <button
                        type="button"
                        onClick={handleNext}
                        className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Next
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProofOfResidenceForm;
