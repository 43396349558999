import React from 'react';
import { Link } from 'react-router-dom';
const Services = () => {
    const syllabusUrl = '/syllabus.pdf';
    const previousYearTamilUrl = '/previousquestionstamil.pdf'
    const previousYearGeneralUrl = '/PreviousYearQuestionGeneral.pdf'
    return (
        <div id="services" className="bg-gray-100 py-12 mt-8 w-full lg:py-24" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Finance</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Welcome to Yaazh finance, where your financial well-being is our top priority. We are delighted to have you as part of our community and look forward to assisting you in achieving your financial goals.</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />*/}
                                <h2 className="font-semibold my-4 text-2xl text-center">Finance</h2>
                                <p>At yaazh finace, we offer a comprehensive range of services designed to meet your banking and financial needs. Whether you are looking to open a savings account, apply for a loan, or explore investment opportunities, our dedicated team is here to provide you with personalized solutions and expert guidance.
                                </p>
                                <p> Start solving these papers to familiarize yourself with the exam format, time management, and types of questions asked. Good luck with your preparation!</p>
                                <br />
                            </div>
                        </div>
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                        </div>
                    </div>
                </div>
            </section>
            <br />

        </div>
    )
}

export default Services;