import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoanCalculator from './LoanCalculator';
import { useNavigate } from 'react-router-dom';

const PersonalLoan = () => {

  const [loanAmount, setLoanAmount] = useState('');
  const [loanPurpose, setLoanPurpose] = useState('');
  const [tenure, setTenure] = useState('');

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/applynow');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Loan Amount: ${loanAmount}, Loan Purpose: ${loanPurpose}, Tenure: ${tenure} months`);
  };

  return (
    <div className='md:mt-28 '>
      <div id="services" className="bg-gray-100 py-12 " >
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Personal Loan</h2>
            <div className='flex justify-center'>
              <div className='w-24 border-b-4 border-blue-900'></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">A personal loan is an unsecured loan provided by financial institutions that can be used for various personal expenses, typically repaid in fixed monthly installments over a specified period:</h2>
          </div>
          <div >
            <LoanCalculator />
          </div>
          <div className="px-12" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <h2 className="font-semibold my-4 text-2xl text-center">Personal Loan</h2>
                  <p>A personal loan is a type of unsecured loan that individuals can borrow from banks, credit unions, or online lenders. It is typically used for personal expenses such as consolidating debt, home improvements, or unexpected medical bills. The loan amount, interest rate, and repayment terms vary based on the borrower's creditworthiness. Unlike secured loans, personal loans do not require collateral. The application process involves a credit check and proof of income. Repayment is usually done in fixed monthly installments over a set period, typically ranging from one to seven years.</p>
                  <h2 className="font-semibold my-4 text-2xl "> Eligibility Criteria</h2>
                  <p>Credit Score,Income,Employment Status,Age</p>
                  <br />

                </div>
              </div>
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <form onSubmit={handleSubmit} className="">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Loan Amount:
                      </label>
                      <input
                        type="number"
                        value={loanAmount}
                        onChange={(e) => setLoanAmount(e.target.value)}
                        max="500000"
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />

                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        LoanPurpose:
                        <select
                          value={loanPurpose}
                          onChange={(e) => setLoanPurpose(e.target.value)}
                          required
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                          <option value="">Select Purpose</option>
                          <option value="personal">Personal Loan</option>
                          <option value="education">Education Loan</option>
                        </select>
                      </label>
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Tenure:
                        <select
                          value={tenure}
                          onChange={(e) => setTenure(e.target.value)}
                          required
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                          <option value="">Select Tenure</option>
                          <option value="6">6 months</option>
                          <option value="12">12 months</option>
                          <option value="24">24 months</option>
                          <option value="36">36 months</option>
                        </select>
                      </label>
                    </div>


                    <Link className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                      <button onClick={handleButtonClick}>Apply Now</button>
                    </Link>

                    {/* <HashLink
                      className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                      smooth
                      to="/applynow"
                    >
                      Apply Now
                    </HashLink> */}


                  </form>
                </div>
              </div>

            </div>
          </div>
        </section>
        <br />

      </div>
    </div>
  );
};

export default PersonalLoan;
