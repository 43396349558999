import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  ScrollRestoration,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import NavBar from './components/Navbar/NavBar';
import Footer from './components/Footer';
import { ACCESS_TOKEN } from './config/Config';
import './App.css';
import AdminDashboard from './pages/AdminDashboard';
import { StateContext } from './contexts/StateContext';
import { useContextSelector } from 'use-context-selector';
import Intro from './components/Intro';
import Services from './components/Services';
import GSTPage from './pages/Gst';
import TaxInfoPage from './pages/Tax';
import ApplyNow from './pages/ApplyNow';
import LoanInformation from './pages/LoanInformation';
import PanForm from './pages/PanForm';
import MobileVerificationForm from './pages/MobileVerification';
import EmailVerificationForm from './pages/EmailVerification';
import PersonalInformationForm from './pages/PersonalInformation';
import WorkInformationForm from './pages/AboutYourWork';
import BorrowedBeforeForm from './pages/BorrowedBefore';
import ProofOfResidenceForm from './pages/ResidenceForm';
import BankDetails from './pages/BankDetails';
import UploadDocuments from './pages/UploadDocuments';
import MortageLoan from './pages/MortageLoan';
import PersonalLoan from './pages/PersonalLoan';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  const [userData, setUserData] = useState({});


  useDocTitle("Yaazh Finance");
  useEffect(() => {
    setAuthenticationStatus();
  }, []);
  function setAuthenticationStatus() {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      let token = localStorage.getItem(ACCESS_TOKEN);
      const base64Url = token?.split(".")[1];
      const base64 = base64Url?.replace("-", "+").replace("_", "/");
      token = base64 ? JSON.parse(window.atob(base64)) : null;
      setIsLoggedIn(false);
      if (token) {
        //alert("parsed Token" + JSON.stringify(token));
        setUserData(token);
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }
  }
  const Layout = () => {
    return (
      <div>
        <NavBar />
        <ScrollRestoration />
        <Outlet />
        <Footer />

      </div>
    );
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Intro />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/services" element={<Services />} />
          <Route path="/emailverification" element={<EmailVerificationForm />} />
          <Route path="/aboutWork" element={<WorkInformationForm />} />
          <Route path="/personalLoan" element={<PersonalLoan />} />
          <Route path="/mortageLoan" element={<MortageLoan />} />
          <Route path="/panForm" element={<PanForm />} />
          <Route path="/mobileVerification" element={<MobileVerificationForm />} />
          <Route path="/personalInformation" element={<PersonalInformationForm />} />
          <Route path="/adminDashboard" element={<AdminDashboard />} />
          <Route path="/gst" element={<GSTPage />} />
          <Route path="/tax" element={< TaxInfoPage />} />
          <Route path="/applynow" element={< ApplyNow />} />
          <Route path="/loanInformation" element={< LoanInformation />} />
          <Route path="/borrowedBefore" element={< BorrowedBeforeForm />} />
          <Route path="/proofOfResidence" element={< ProofOfResidenceForm />} />
          <Route path="/bankDetails" element={< BankDetails />} />
          <Route path="/uploadDocuments" element={< UploadDocuments />} />
        </Route>
      </Route>
    )
  );

  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>

  );
}


export default App;


