import React, { Component, Suspense } from 'react';
import Lottie from 'react-lottie';

export default class DisplayLottie extends Component {
  render() {
    const { animationData } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
    };

    return (
      <Suspense >
        <Lottie options={defaultOptions} isClickToPauseDisabled />
      </Suspense>
    );
  }
}